<template>
  <v-form>
    <v-row dense>
      <v-col cols="2">
        <v-autocomplete
          dense
          outlined
          v-model="Series"
          :items="seriesData"
          label="Num Series"
          item-text="SeriesName"
          item-value="id"
          @change="setDocNumber(Series)"
        ></v-autocomplete>
      </v-col>

      <v-col cols="2">
        <v-text-field
          :filled="manualNum ? false : true"
          :readonly="manualNum ? false : true"
          outlined
          dense
          label="Number"
          v-model="record.Series"
        ></v-text-field>
      </v-col>

      <v-col cols="2">
        <DatePicker
          @date="setRefDate"
          :myDate="record.RefDate"
          title="Posting Date"
        ></DatePicker>
      </v-col>

      <v-col cols="2">
        <DatePicker
          @date="setDueDate"
          :myDate="record.DueDate"
          title="Due Date"
        ></DatePicker>
      </v-col>

      <v-col cols="2">
        <DatePicker
          @date="setTaxDate"
          :myDate="record.TaxDate"
          title="Doc Date"
        ></DatePicker>
      </v-col>

      <v-col cols="2">
        <v-text-field
          readonly
          filled
          outlined
          dense
          label="Origin"
          v-model="record.originName"
        ></v-text-field>
      </v-col>

      <v-col cols="2">
        <v-text-field
          readonly
          filled
          outlined
          dense
          label="Origin No."
          v-model="record.originNo"
        ></v-text-field>
      </v-col>

      <v-col cols="2">
        <v-text-field
          readonly
          filled
          outlined
          dense
          label="Trans No."
          v-model="record.TransNo"
        ></v-text-field>
      </v-col>

      <v-col cols="2">
        <v-text-field
          outlined
          dense
          label="Ref 1"
          v-model="record.TransNo"
        ></v-text-field>
      </v-col>

      <v-col cols="2">
        <v-text-field
          outlined
          dense
          label="Ref 2"
          v-model="record.TransNo"
        ></v-text-field>
      </v-col>

      <v-col cols="2">
        <v-text-field
          outlined
          dense
          label="Ref 3"
          v-model="record.TransNo"
        ></v-text-field>
      </v-col>

      <v-col cols="2">
        <v-text-field
          outlined
          dense
          label="Remarks"
          v-model="record.Remark"
        ></v-text-field>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="6">
        <v-autocomplete
          dense
          outlined
          v-model="selectedAccount"
          :items="accounts"
          label="Search Product Account"
          item-text="AcctName"
          item-value="id"
          @change="accountChange"
        ></v-autocomplete>
      </v-col>
    </v-row>

    <!-- start of journal entry document_lines -->
    <v-row>
      <v-col cols="12">
        <v-data-table hide-default-footer :headers="headers" :items="jdt1">
          <template v-slot:item.Credit="props">
            <v-text-field
               :filled="docCreate == false"
              :readonly="docCreate == false"
              outlined
              dense
              v-model="props.item.Credit"
              type="number"
            ></v-text-field>
          </template>
          <template v-slot:item.Debit="props">
            <v-text-field
              :filled="docCreate == false"
              :readonly="docCreate == false"
              outlined
              dense
              v-model="props.item.Debit"
              type="number"
            ></v-text-field>
          </template>
          <template v-slot:body.append>
            <tr>
              <td></td>
              <td></td>
              <td>
                <v-text-field
                  filled
                  readonly
                  outlined
                  dense
                  v-model="LocTotal"
                ></v-text-field>
              </td>
              <td>
                <v-text-field
                  filled
                  readonly
                  outlined
                  dense
                  v-model="LocTotal"
                ></v-text-field>
              </td>
            </tr>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
    <!-- end of document_lines -->
    <v-row>
      <v-col cols="12">
        <v-btn color="accent" @click="sendData">
          <v-icon left>mdi-content-save</v-icon>Save
        </v-btn>
      </v-col>
    </v-row>

    <!-- snackbar -->
    <snackbar ref="snackbar"></snackbar>
  </v-form>
</template>


<script>
export default {
  props: {
    initial: {
      type: Object,
      required: true,
    },
    initialJdt1: {
      type: Array,
    },
    initialLocTotal: {
      type: Number,
    },
    initialOriginNo: {
      type: String,
    },
    initialOriginName: {
      type: String,
    },
    docCreate: {
      type: Boolean
    }
  },
  data: () => ({
    record: {},
    Series: null,
    seriesData: [],
    manualNum: false,
    selectedAccount:null,
    accounts: [],
    LocTotal: null,
    originNo: null,
    originName: null,
    date: new Date().toISOString().substr(0, 10),
    headers: [
      { text: "Acct/Bp Code", value: "AcctCode" },
      { text: "Acct/Bp Name", value: "AcctName" },
      { text: "Debit", value: "Debit" },
      { text: "Credit", value: "Credit" },
    ],
    jdt1: [],
  }),
  watch: {
    initial: {
      handler: "init",
      immediate: true,
    },
    initialJdt1: {
      handler: "initJdt1",
      immediate: true,
    },
    initialLocTotal: {
      handler: "initLocTotal",
      immediate: true,
    },
    initialOriginName: {
      handler: "initOriginName",
      immediate: true,
    },
    initialOriginNo: {
      handler: "initOriginNo",
      immediate: true,
    },
  },
  methods: {
    init(val) {
      this.record = { ...val };
    },
    initJdt1(val) {
      this.jdt1 = [...val];
    },
    initLocTotal(val) {
      this.LocTotal = val;
    },
    initOriginName(val) {
      this.record.originName = val;
    },
    initOriginNo(val) {
      this.record.originNo = val;
    },
    setTaxDate(date) {
      this.record.TaxDate = date;
    },
    setDueDate(date) {
      this.record.DueDate = date;
    },
    setRefDate(date) {
      this.record.RefDate = date;
    },
    sendData() {
      this.record.document_lines = this.jdt1;
      console.log(this.record, 'r data')
      // console.log(this.record, 'journal data');
      this.$emit("data", this.record);
    },
    accountChange(id) {
      const account = this.accounts.find(record=> {
        return record.id === id;
      });
      const jdt1Record = {
        Account: account.id,
        AcctCode: account.AcctCode,
        AcctName: account.AcctName,
        Debit: null,
        Credit: null,
        DueDate: new Date().toISOString().substr(0, 10),
        TaxDate: new Date().toISOString().substr(0, 10)
      };
      this.jdt1.push(jdt1Record);
    },
    setDocNumber(id) {
      const seriesRecord = this.seriesData.find((record) => {
        return record.id === id;
      });
      if (seriesRecord.IsManual === "Y") {
        this.record = { ...this.record, ItemCode: null };
        this.manualNum = true;
      } else {
        this.manualNum = false;
        this.record = { ...this.record, ItemCode: seriesRecord.NextNumber };
      }
      this.Series = seriesRecord.id;
    },
    formSettings() {
      const self = this;
      this.$store
        .dispatch("get", `/form_settings/18`)
        .then((res) => {
          self.seriesData = res.Series;
          self.Series = res.DfltSeries.id;
          if (res.DfltSeries.IsManual == "Y") {
            self.manualNum = true;
          }
          self.record.Series = res.DfltSeries.NextNumber;
        })
        .catch((err) => {
          console.log(err, "error");
        });
    },
      getAccounts() {
      const self = this;
      this.$store
        .dispatch("get", `/activeGLaccounts`)
        .then(res => {
          self.accounts = res;
        })
        .catch(err => {
          this.$refs.snackbar.show(err, "red");
        });

        // set default date
        this.record.DueDate = new Date().toISOString().substr(0, 10);
        this.record.TaxDate = new Date().toISOString().substr(0, 10);
        this.record.RefDate = new Date().toISOString().substr(0, 10);
    }
  },
  created() {
    this.formSettings();
    this.getAccounts();
  },
};
</script>